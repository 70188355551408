<template>
      <div class="payment-info">
      <v-data-table
        :options.sync="options"
        :headers="headers"
        :items="datas"
        class="table-officecare-company"
        :mobile-breakpoint="0"
        hide-default-footer
        :loading="myloadingvariable"
        :items-per-page="-1"
        :custom-sort="customSort"
      >
        <template v-slot:top>
          <v-row
            class="d-flex flex align-center justify-space-between px-0 py-0"
          >
            <v-col cols="12" md="8">
              <v-toolbar-title class="package-title text-capitalize pl-2"
              >{{ $t("tab.company_transaction") }}
            </v-toolbar-title>
            </v-col>
            <v-col
              cols="12"
              md="4"
              class="d-flex justify-end align-center pr-0"
            >
              <v-text-field
                class="searchField fontSet pl-3 pr-3"
                outlined
                dense
                hide-details
                v-model="search"
                append-icon="mdi-magnify"
                :label="$t('search') + ' ...'"
              ></v-text-field>
              <!-- </v-col>
            <v-col class="d-flex justify-end" cols="3" md="1"> -->
              <div>
                <v-select
                  class="footerHide"
                  v-model="perPage"
                  :items="itemShowing"
                ></v-select>
              </div>
            </v-col>
          </v-row>
        </template>
        <template v-slot:item="{ item }">
          <tr>
            <td valign="top" class="pt-2 text-center">
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    small
                    @click="editItem(item)"
                    v-bind="attrs"
                    v-on="on"
                    style="color: #00a4b3; padding-right: 10px"
                  >
                    mdi-eye
                  </v-icon>
                </template>
                <span>{{ $t("detail") }}</span>
              </v-tooltip>
              <template v-if="item.registration_group !== '-'">
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      small
                      @click="invoiceBatch(item)"
                      v-bind="attrs"
                      v-on="on"
                      style="color: #00a4b3"
                    >
                      mdi-file-document-outline
                    </v-icon>
                  </template>
                  <span>{{ $t("invoice") }}</span>
                </v-tooltip>
                  </template>
            </td>
            <td valign="top" class="pt-2">{{ item.registration_group }}</td>
            <!-- <td valign="top" class="pt-2">{{ item.kode }}</td> -->
            <td valign="top" class="pt-2">
              {{ formatDate(item.booking_date) }}
            </td>
            <td valign="top" class="pt-2">{{ item.plant }}</td>
            <td valign="top" class="pt-2">
              <p class="list-table text-right" style="margin-bottom: 0px">
                {{ formatPrice(item.total_invoice) }}
              </p>
            </td>
          </tr>
        </template>

        <template v-slot:footer>
          <div class="d-flex align-end justify-end pa-3">
            <p class="mb-0 mr-3" style="font-size: 12px; font-weight: 600">
              Total :
            </p>
            <p class="mb-0" style="font-size: 12px; font-weight: 600">
              {{ formatPrice(totalRevenue) }}
            </p>
          </div>
          <v-divider></v-divider>
          <v-row
            class="d-flex flex align-center justify-space-between footer-payment"
          >
            <v-col cols="12" md="8">
              <p class="footer-showing">
                showing {{ itemShowStart }} to {{ itemShowStop }} of
                {{ itemsTotal }} entries
              </p>
            </v-col>
            <v-col cols="12" md="4" class="d-flex justify-end">
              <custom-pagination
                @changePage="val => (page = val)"
                :totalPage="itemLength"
                :activePage="page"
              />
              <!-- :length="props.pagination.pageCount" -->
            </v-col>
          </v-row>
        </template>
      </v-data-table>

    <!-- start modal popup daily referral month (dialog_referral)-->
    <template>
      <v-row justify="center">
        <v-dialog v-model="dialog_referral">
          <v-card>
            <v-card-text class="px-5 py-2">
              <!-- <v-row>
                <v-col cols="12" md="12"> -->
                  <div class="payment-info">
                    <v-data-table
                      :headers="headersPatient"
                      :items="datasPatient"
                      class="table-officecare-company"
                      :class="{
                        'table-stick-popup-transaction':
                          datasPatient.length > 15,
                      }"
                      :mobile-breakpoint="0"
                      hide-default-footer
                      :loading="myloadingvariablePopup"
                      :items-per-page="-1"
                    >
                      <template v-slot:top>
                        <v-card-title class="pa-0">
                          <v-col cols="12" md="8" no-gutters>
                            <h4 class="package-title">{{ $t("tab.company_transaction") }} {{ $t("detail") }}</h4>
                          </v-col>
                          <v-col
                            cols="12"
                            md="4"
                            no-gutters
                            class="d-flex align-center justify-end pr-0"
                          >
                            <v-text-field
                              class="searchField fontSet pr-3"
                              outlined
                              dense
                              hide-details
                              v-model="searchPopup"
                              :label="$t('search') + ' ...'"
                              append-icon="mdi-magnify"
                            ></v-text-field>
                            <div>
                              <v-select
                                class="footerHide pt-0"
                                v-model="perPagePopup"
                                :items="itemShowingPopup"
                                hide-details
                              ></v-select>
                            </div>
                          </v-col>
                        </v-card-title>
                      </template>
                      <template v-slot:item="{ item }">
                        <tr class="sticky-popup-hdt">
                          <td valign="top" class="pt-2 sticky-popup-oct1">
                            {{ item.registration_group }}
                          </td>
                          <td valign="top" class="pt-2">
                            <template v-if="item.booking_date == '-'">
                              {{ item.booking_date }}
                            </template>
                            <template v-else>
                              {{ formatDate(item.booking_date) }}
                            </template>
                          </td>
                         
                          <td valign="top" class="pt-2">
                            <!-- {{ item.registration_number }} -->
                            <a
                              href="javascript:void(0);"
                              @click="openDialogInvoice(item.order_number)"
                              >{{ item.order_number }}</a
                            >
                          </td>
                          <td valign="top" class="pt-2">
                            {{ item.id_number }}
                          </td>
                          <td valign="top" class="pt-2">
                            {{ item.patient }}
                          </td>
                          <td valign="top" class="pt-2">
                            {{ item.department }}
                          </td>
                          <td valign="top" class="pt-2">
                            {{ item.job_position }}
                          </td>
                          <td valign="top" class="pt-2">{{ item.plant }}</td>
                          <td valign="top" class="pt-2">{{ item.type }}</td>
                          <td valign="top" class="pt-2">
                            {{ item.item_name }}
                          </td>
                          <td valign="top" class="pt-2">{{ item.status }}</td>
                          <td
                            valign="top"
                            class="pt-2 sticky-popup-oct2"
                            align="right"
                          >
                            <template v-if="item.amount_total == '-'">
                              {{ item.amount_total }}
                            </template>
                            <template v-else>
                              {{ formatPrice(item.amount_total) }}
                            </template>
                          </td>
                        </tr>
                      </template>
                      <template v-slot:footer>
                        <v-row
                          class="d-flex flex align-center justify-space-between footer-payment"
                        >
                          <v-col cols="12" md="8">
                            <p class="footer-showing">
                              showing {{ itemShowStartPopup }} to
                              {{ itemShowStopPopup }} of
                              {{ itemsTotalPopup }} entries
                            </p>
                          </v-col>
                          <v-col cols="12" md="4" class="d-flex justify-end">
                            <custom-pagination
                              @changePage="val => (pagePopup = val)"
                              :totalPage="itemLengthPopup"
                              :activePage="pagePopup"
                          /></v-col>
                        </v-row>
                      </template>
                    </v-data-table>
                  </div>
                <!-- </v-col>
              </v-row> -->
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="primary darken-1" text @click="closeEdit()">
                {{ $t("close") }}
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-row>
    </template>
    <DialogInvoice
      :item="dataDialogInvoice"
      :tampilkan="dialog_invoice"
      :tableFilter="tableInvoice"
      @toggleDialog="is_show => toggleDialogInvoice(is_show)"
      v-if="dialog_invoice"
    />
    <DialogInvoiceBatch
      :item="dataDialogInvoiceBatch"
      :tampilkan="dialog_invoice_batch"
      :tableFilterBatch="tableInvoiceBatch"
      @toggleDialog="is_show => toggleDialogInvoiceBatch(is_show)"
      v-if="dialog_invoice_batch"
    />
      </div>
</template>

<script>
// import TabContent from "@/components/v2/tabs/index.vue";
import API from "@/service/api_service.js";
import Auth from "@/service/auth_service";
import moment from "moment";
import DialogInvoice from "@/components/v2/dialog/dialogCompanyInvoice.vue";
import DialogInvoiceBatch from "@/components/v2/dialog/dialogInvoiceBatch.vue";
import CustomPagination from "@/components/v3/pagination.vue";

export default {
  components: { DialogInvoice, DialogInvoiceBatch, CustomPagination },
  data: () => ({
    page: 1,
    pagePopup: 1,
    options: null,
    itemsTotal: 0,
    itemsTotalPopup: 0,
    itemLength: 1,
    itemLengthPopup: 1,
    itemShowStart: 0,
    itemShowStartPopup: 0,
    itemShowStop: 0,
    itemShowStopPopup: 0,
    itemShowing: [
      { value: 25, text: "25" },
      { value: 50, text: "50" },
      { value: 70, text: "70" },
      { value: 100, text: "100" },
    ],
    itemShowingPopup: [
      { value: 25, text: "25" },
      { value: 50, text: "50" },
      { value: 70, text: "70" },
      { value: 100, text: "100" },
    ],
    perPage: 25,
    perPagePopup: 25,
    myloadingvariable: true,
    myloadingvariablePopup: true,
    footerProps: { "items-per-page-options": [25, 50, 70, -1] },
    myDepoHistoryListCount: 0,
    showNavbar: true,
    showCart: false,
    showCartDesktop: false,
    showSearch: false,
    showLogo: true,
    search: "",
    searchPopup: "",
    dialogDelete: false,
    formHasError: false,
    headers: [],
    datas: [],
    headersPatient: [],
    datasPatient: [],
    dialog_referral: false,
    dialog_invoice_batch: false,
    editedIndex: -1,
    registration_group: null,
    kode: null,
    booking_date: null,
    status: null,
    totalRevenue: 0,
    totalPayment: 0,
    totalQty: 0,
    totalPrice: 0,
    totalTax: 0,
    totalDiscount: 0,
    errorMessages: "",
    defaultItem: {
      registration_group: "",
      kode: "",
      booking_date: "",
      status: "",
    },
    getAuth: null,
    tableInvoice: [],
    tableInvoiceBatch: [],
    dialog_invoice: false,
    dataDialogInvoice: {
      order_number: null,
      list: [],
      data: {
        metadata: {},
      },
    },
    dataDialogInvoiceBatch: {
      registration_group: null,
      list: [],
      data: {
        metadata: {},
      },
    },
    searchData: "",
    idTemp: "",
  }),
  created() {
    this.headersPatient = [
      {
        text: this.$t("page.profile.booking_batch"),
        align: "center",
        value: "registration_group",
        class: "sticky-popup-hoct1",
        sortable: false,
      },
      {
        text: this.$t("page.profile.booking_date"),
        value: "booking_date",
        sortable: true,
        align: "center",
      },
      {
        text: this.$t("page.referral.order_num"),
        sortable: false,
        value: "order_number",
        align: "center",
      },
      {
        text: this.$t("page.profile.patient_id"),
        sortable: false,
        value: "id_number",
        align: "center",
      },
      {
        text: this.$t("page.profile.patient_name"),
        value: "patient",
        sortable: false,
        align: "center",
      },
      {
        text: this.$t("field.department"),
        value: "department",
        sortable: false,
        align: "center",
      },
      {
        text: this.$t("field.jobpost"),
        value: "job_position",
        sortable: false,
        align: "center",
      },
      {
        text: this.$t("field.branch"),
        sortable: false,
        value: "plant",
        align: "center",
      },
      {
        text: this.$t("field.type"),
        sortable: false,
        value: "type",
        align: "center",
      },
      {
        text: this.$t("page.profile.item_name"),
        value: "item_name",
        sortable: false,
        align: "center",
      },
      {
        text: this.$t("page.profile.status"),
        value: "status",
        sortable: false,
        align: "center",
      },
      {
        text: this.$t("page.profile.amount"),
        value: "amount_total",
        sortable: false,
        class: "sticky-popup-hoct2",
        align: "center",
      },
    ];
    this.headers = [
      {
        text: this.$t("page.profile.actions"),
        value: "actions",
        sortable: false,
        align: "center",
      },
      {
        text: this.$t("page.profile.booking_batch"),
        align: "center",
        value: "registration_group",
        sortable: false,
      },
      {
        text: this.$t("field.booking_date"),
        value: "booking_date",
        sortable: true,
        align: "center",
      },
      {
        text: this.$t("field.branch"),
        value: "branch_name",
        sortable: false,
        align: "center",
      },
      {
        text: this.$t("page.profile.amount"),
        value: "total_invoice",
        sortable: false,
        align: "center",
      },
    ];
    this.getDataEmployee();
  },
  mounted() {
    this.getAuth = Auth.getTypeUser();
  },
  watch: {
    dialogDelete(val) {
      val || this.closeDelete();
    },
    page: function () {
      this.getDataEmployee();
    },
    perPage: function () {
      if (this.page != 1) {
        this.page = 1;
      } else {
        this.getDataEmployee();
      }
    },
    pagePopup: function () {
      this.getInitialRevenuePopup(this.idTemp);
    },
    perPagePopup: function () {
      if (this.pagePopup != 1) {
        this.pagePopup = 1;
      } else {
        this.getInitialRevenuePopup(this.idTemp);
      }
    },
    search: function () {
      this.page = 1;
      this.getDataEmployee();
    },
    searchPopup: function () {
      this.pagePopup = 1;
      this.getInitialRevenuePopup(this.idTemp);
    },
  },

  methods: {
    customSort(items, index, isDesc) {
      items.sort((a, b) => {
          if (index[0]=='booking_date') {
            if (!isDesc[0]) {
                return new Date(b[index]) - new Date(a[index]);
            } else {
                return new Date(a[index]) - new Date(b[index]);
            }
          }
          else {
            if(typeof a[index] !== 'undefined'){
              if (!isDesc[0]) {
                 return a[index].toLowerCase().localeCompare(b[index].toLowerCase());
              }
              else {
                  return b[index].toLowerCase().localeCompare(a[index].toLowerCase());
              }
            }
          }
      });
      return items;
    },
    async currentDataItems() {
      try {
        const res = await API.get(
          `${process.env.VUE_APP_API_TRUST}employee/get-booking-list?query=${this.searchData}`,
          {
            "Content-Type": "application/json",
            Authorization: `Bearer ${Auth.getToken()}`,
          }
        );

        // Check result exist
        if (res.results) {
          this.datas = res.results;
        }
      } catch (error) {
        console.log(error);
      }
    },
    setPageMyDepoHistory(data) {
      this.myDepoHistoryListCount = data.pageStop - data.pageStart;
    },
    formatPrice(price) {
      return Intl.NumberFormat().format(price);
    },
    formatDate(date) {
      if (!date) return null;
      return moment(date)
        .locale(this.$i18n.locale)
        .format("DD-MMM-YYYY")
        .toUpperCase();
    },
    toast(status, messages) {
      this.$swal
        .mixin({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
          didOpen: toast => {
            toast.addEventListener("mouseenter", this.$swal.stopTimer);
            toast.addEventListener("mouseleave", this.$swal.resumeTimer);
          },
        })
        .fire({
          icon: status,
          title: messages,
        });
    },
    async editItem(item) {
      this.getInitialRevenuePopup(item.id);
      this.dialog_referral = true;
    },
    async invoiceBatch(item) {
      this.getInvoiceBatchPopup(item);
      this.dialog_invoice_batch = true;
    },
    async getInvoiceBatchPopup(item) {
      let registration_group = item.registration_group;
      try {
        const res = await API.get(
          `${process.env.VUE_APP_API_TRUST}invoices/get-invoice-company?invoice_group=` +
            registration_group +
            ``,
          {
            "Content-Type": "application/json",
            Authorization: `Bearer ${Auth.getToken()}`,
          }
        );

        this.dataDialogInvoiceBatch.data = res.results;
        this.dataDialogInvoiceBatch.list = res.results.data;
        this.totalPayment = 0;
        this.totalPrice = 0;
        this.totalTax = 0;
        this.totalDiscount = 0;

        this.dataDialogInvoiceBatch.list.forEach(item => {
          this.totalPayment = this.totalPayment + parseInt(item.ext_price);
          this.totalPrice = this.totalPrice + parseInt(item.price);
          this.totalTax = this.totalTax + parseInt(item.withholdingtax);
          this.totalDiscount = this.totalDiscount + parseInt(item.discount);
        });

        this.totalQty = res.results.data.length;
        this.dataDialogInvoiceBatch.data.metadata.grand_total =
          this.totalPayment;
        this.dataDialogInvoiceBatch.data.metadata.grand_price = this.totalPrice;
        this.dataDialogInvoiceBatch.data.metadata.grand_tax = this.totalTax;
        this.dataDialogInvoiceBatch.data.metadata.grand_discount =
          this.totalDiscount;
        this.dataDialogInvoiceBatch.data.metadata.grand_qty = this.totalQty;

        this.tableInvoiceBatch = this.dataDialogInvoiceBatch.list;
      } catch (error) {
        console.log(error);
      }
    },
    async getInitialRevenuePopup(getDatas) {
      this.idTemp = getDatas;
      try {
        this.myloadingvariablePopup = true;
        // pagination showing start of entries
        let startPagePopup = (this.pagePopup - 1) * this.perPagePopup;
        let showPagePopup = this.perPagePopup;
        this.itemShowStartPopup = startPagePopup + 1;
        this.itemShowStopPopup = startPagePopup + showPagePopup;

        const res = await API.get(
          `${process.env.VUE_APP_API_TRUST}employee/get-booking-detail?query=${this.searchPopup}&id=${getDatas}&offset=${startPagePopup}&per_page=${showPagePopup}`,
          {
            "Content-Type": "application/json",
            Authorization: `Bearer ${Auth.getToken()}`,
          }
        );

        // Check result exist
        if (res.results) {
          var new_data = [];
          res.results.map(function (e) {
            var bookingDateFormat = "";
            if (e.booking_date !== null) {
              bookingDateFormat = moment(e.booking_date)
                .format("DD-MMM-YYYY")
                .toUpperCase();
            } else {
              bookingDateFormat = "-";
            }

            new_data.push({
              id: typeof e.id !== "undefined" ? e.id : "",
              registration_group:
                e.registration_group !== null
                  ? e.registration_group.toUpperCase()
                  : "-",
              booking_date: bookingDateFormat,
              
              order_number:
                e.order_number !== null ? e.order_number.toUpperCase() : "-",
              id_number:
                typeof e.id_number !== "undefined"
                  ? e.id_number.toUpperCase()
                  : "",
              patient:
                typeof e.patient !== "undefined" ? e.patient.toUpperCase() : "",
              department:
                e.department !== null ? e.department.toUpperCase() : "",
              job_position:
                e.job_position !== null ? e.job_position.toUpperCase() : "-",
              plant: e.plant !== null ? e.plant.toUpperCase() : "-",
              type: typeof e.type !== "undefined" ? e.type.toUpperCase() : "",
              item_name:
                typeof e.item_name !== "undefined"
                  ? e.item_name.toUpperCase()
                  : "",
              status:
                typeof e.status !== "undefined" ? e.status.toUpperCase() : "",
              amount_total:
                e.amount_total !== "0" ? e.amount_total.toUpperCase() : "-",
            });
          });

          this.datasPatient = new_data;

          this.itemsTotalPopup = res.total;
          this.itemLengthPopup = Math.ceil(
            this.itemsTotalPopup / showPagePopup
          );
          if (this.itemShowStopPopup < 0) {
            this.itemShowStopPopup = res.total;
          }
          if (this.itemShowStopPopup > res.total) {
            this.itemShowStopPopup = res.total;
          }
          this.myloadingvariablePopup = false;
        }
      } catch (error) {
        console.log(error);
      }
    },
    // editItem(item) {
    //   this.$router.push(
    //     `/${this.$i18n.locale}/company/office-care/detail/${item.id}`
    //   );
    // },
    // deleteItem(item) {
    //   this.editedIndex = this.datas.indexOf(item);
    //   this.editedItem = Object.assign({}, item);
    //   this.dialogDelete = true;
    // },

    // deleteItemConfirm() {
    //   let vm = this;
    //   try {
    //     let form = { person_id: this.datas[this.editedIndex].id };
    //     (async function () {
    //       const res = await API.del(
    //         `${process.env.VUE_APP_API_TRUST}employee/delete`,
    //         {
    //           "Content-Type": "application/json",
    //           Authorization: `Bearer ${Auth.getToken()}`,
    //         },
    //         [form]
    //       );

    //       if (res.statusCode === 200) {
    //         vm.toast("success", vm.$t("success_insert_data"));
    //         setTimeout(function () {
    //           vm.getDataEmployee();
    //         }, 200);
    //       } else if (res.statusCode == 403) {
    //         Object.keys(res.errors).forEach((element) => {
    //           vm.toast(
    //             "error",
    //             typeof res.errors[element] == "object"
    //               ? res.errors[element][0]
    //               : res.errors[element]
    //           );
    //         });
    //       } else {
    //         vm.$swal({
    //           icon: "error",
    //           text: vm.$t("error_try_again_latter"),
    //         });
    //       }
    //     })();
    //   } catch (error) {
    //     this.$swal({
    //       icon: "error",
    //       text: vm.$t("error_try_again_latter"),
    //     });
    //   }
    //   this.closeDelete();
    // },

    // closeDelete() {
    //   this.dialogDelete = false;
    //   this.$nextTick(() => {
    //     this.editedItem = Object.assign({}, this.defaultItem);
    //     this.editedIndex = -1;
    //   });
    // },

    closeEdit() {
      this.dialog_referral = false;
      this.idTemp = "";
    },

    async getDataEmployee() {
      // console.log(`${Auth.getToken()}`);
      try {
        this.myloadingvariable = true;
        // pagination showing start of entries
        let startPage = (this.page - 1) * this.perPage;
        let showPage = this.perPage;
        this.itemShowStart = startPage + 1;
        this.itemShowStop = startPage + showPage;

        const res = await API.get(
          `${process.env.VUE_APP_API_TRUST}employee/get-booking-list?query=${this.search}&approved=true&offset=${startPage}&per_page=${showPage}`,
          {
            "Content-Type": "application/json",
            Authorization: `Bearer ${Auth.getToken()}`,
          }
        );

        // Check result exist
        if (res.results) {
          const filterNewData = res.results;

          filterNewData.forEach(data => {
            data.total_invoice = data.total_invoice || 0;
            this.totalRevenue += parseInt(data.total_invoice);
          });
          var new_data = [];
          filterNewData.map(function (e) {
            var dateCreate =
              typeof e.booking_date !== "undefined" && e.booking_date
                ? moment(e.booking_date)
                    .format("DD-MMM-YYYY h:mm:ss a")
                    .toUpperCase()
                : "-";
            new_data.push({
              id: typeof e.id !== "undefined" ? e.id : "",
              registration_group:
                e.registration_group !== null
                  ? e.registration_group.toUpperCase()
                  : "-",
              kode: typeof e.kode !== "undefined" ? e.kode.toUpperCase() : "",
              booking_date: dateCreate,
              plant: e.plant !== null ? e.plant.toUpperCase() : "-",
              total_invoice: e.total_invoice !== "0" ? e.total_invoice : "0",
            });
          });

          this.datas = new_data;

          this.itemsTotal = res.total;
          this.itemLength = Math.ceil(this.itemsTotal / showPage);
          if (this.itemShowStop < 0) {
            this.itemShowStop = res.total;
          }
          if (this.itemShowStop > res.total) {
            this.itemShowStop = res.total;
          }
          this.myloadingvariable = false;
        }
      } catch (error) {
        console.log(error);
      }
    },
    toggleDialogInvoice(toggle) {
      this.dialog_invoice = toggle;
    },
    toggleDialogInvoiceBatch(toggle) {
      this.dialog_invoice_batch = toggle;
    },
    async openDialogInvoice(item) {
      this.dataDialogInvoice.order_number = item;
      this.getOrderDetailInvoiceMCU();
      this.dialog_invoice = true;
    },
    async getOrderDetailInvoiceMCU() {
      let order_number = this.dataDialogInvoice.order_number;
      try {
        const res = await API.get(
          `${process.env.VUE_APP_API_TRUST}invoices/get-detail?order_number=` +
            order_number +
            ``,
          {
            "Content-Type": "application/json",
            Authorization: `Bearer ${Auth.getToken()}`,
          }
        );
        this.dataDialogInvoice.data = res.results;
        this.dataDialogInvoice.list = res.results.data;

        //cari harga total karena diskon belum kehitung
        let grand_total =
          parseInt(this.dataDialogInvoice.data.metadata.amount_total) -
          parseInt(this.dataDialogInvoice.data.metadata.total_discount);
        this.dataDialogInvoice.data.metadata.grand_total = grand_total;

        let groupCategory = this.dataDialogInvoice.data.data.reduce((r, a) => {
          r[a.sub_category] = [...(r[a.sub_category] || []), a];
          return r;
        }, {});

        this.groupItemCategory = Object.entries(groupCategory).map(arr => ({
          nameCategory: arr[0],
          dataCategory: arr[1],
        }));

        let filterItem = [];
        this.groupItemCategory.forEach(element => {
          filterItem = [
            ...filterItem,
            { text: element.nameCategory, type: "category" },
          ];
          let groupSubcategory = element.dataCategory.reduce((r, a) => {
            r[
              a.product_name
              // this.$i18n.locale == "id"
              //   ? a.product_name
              //   : a[`product_name_${this.$i18n.locale}`]
            ] = [
              ...(r[
                a.product_name
                // this.$i18n.locale == "id"
                //   ? a.product_name
                //   : a[`product_name_${this.$i18n.locale}`]
              ] || []),
              a,
            ];
            return r;
          }, {});
          this.groupItemSubategory = Object.entries(groupSubcategory).map(
            arr => ({
              nameSubcategory: arr[0],
              dataSubcategory: arr[1],
            })
          );

          this.groupItemSubategory.forEach(element => {
            filterItem = [
              ...filterItem,
              { text: element.nameSubcategory, type: "subcategory" },
            ];
            element.dataSubcategory.forEach(element => {
              filterItem = [...filterItem, { ...element, type: "item" }];
            });
          });
        });

        this.tableInvoice = filterItem;
      } catch (error) {
        console.log(error);
      }
    },
  },
};
</script>
<style>
.table-officecare-company thead {
  z-index: 5 !important;
}
.table-officecare-company thead {
  background: #ddd !important;
  font-weight: bold;
  color: #000 !important;
}
.table-officecare-company thead tr th {
  height: 38px !important;
  white-space: nowrap;
}
.table-officecare-company tbody tr td {
  font-size: 12px !important;
  height: 35px !important;
  white-space: nowrap;
}
.table-officecare-company tbody tr:hover {
  background-color: #eee !important;
}

@media screen and (min-width: 1023px) {
  .sticky-popup-oct1 {
    position: sticky !important;
    position: -webkit-sticky !important;
    left: 0;
    background: #fff;
    z-index: 2;
  }
  .sticky-popup-oct2 {
    position: sticky !important;
    position: -webkit-sticky !important;
    right: 0;
    background: #fff;
    z-index: 2;
  }
  .sticky-popup-hoct1 {
    position: sticky !important;
    position: -webkit-sticky !important;
    left: 0;
    background: #ddd;
    z-index: 5;
  }
  .sticky-popup-hoct2 {
    position: sticky !important;
    position: -webkit-sticky !important;
    right: 0;
    background: #ddd;
    z-index: 5;
  }
}
</style>
<style lang="scss" scoped>
@import "@/assets/css/custom.scss";
.theme--light.v-data-table {
  background-color: transparent !important;
}
.content-table {
  padding: 15px;
  @media screen and (max-width: 960px) {
    padding: 5px 18px 18px 18px;
  }
}
@media screen and (max-width: 480px) {
  .content-table {
    padding: 0px !important;
  }
}
@media screen and (min-width: 1023px) {
  .sticky-popup-hdt:hover {
    .sticky-popup-oct1 {
      background: #eee !important;
    }
    .sticky-popup-oct2 {
      background: #eee !important;
    }
    .v-data-table
      > .v-data-table__wrapper
      tbody
      tr:first-child:hover
      td:first-child {
      background: #eee !important;
    }
  }
}
.items-product {
  width: 300px;
  white-space: break-spaces;
}
.searchField {
  max-width: 500px;
}
.button--active {
  background: $main_3;
  color: white;
}
.button--outline {
  color: $main_3;
  border: 1px solid $main_3;
  background: white;
}
.button--download {
  background: #79c4c6;
  color: white;
}
.button-box {
  box-sizing: border-box;
  border-radius: 10px;
  padding: 8px 16px;
  outline: none;
}
.card--result {
  background: $gray_3;
  padding: 18px;
  border-radius: 16px;
  width: 100%;
  // max-width: 300px;
  color: $gray_2;
  h4 {
    color: $gray_1;
    font-size: 20px;
  }
  p {
    margin: 0;
  }
  .card--result__item {
    padding: 6px 0;
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid $gray_2;
    &:nth-last-child(1) {
      border: none;
    }
  }
}

.status--inprogress {
  color: $yellow_1;
  font-weight: 600;
}
.status--complete {
  color: $green_1;
  font-weight: 600;
}
.status--cancel {
  color: $red_1;
  font-weight: 600;
}
.tabs {
  background: #e5e5e5 !important;
}

td {
  font-size: 12px !important;
  vertical-align: top !important;
}
.fontSet {
  font-size: 14px !important;
}
.button {
  background-color: #00a4b3 !important;
  border-radius: 5px;
  color: white !important;
  font-size: 14px;
  text-align: center;
  text-transform: capitalize;
  height: 30px !important;
  min-width: 100px !important;
}
a {
  text-decoration: none;
}
.fontSet {
  font-size: 14px !important;
}
.box-page-new {
  margin: 20px;
  border-radius: 4px !important;
}

.v-data-table > .v-data-table__wrapper > table > tbody > tr > td {
  font-size: 12px;
  vertical-align: baseline;
  padding-top: 6px;
  padding-bottom: 6px;
  height: 25px !important;
}

.v-data-table > .v-data-table__wrapper > table > thead > tr > th {
  height: 35px !important;
}
.package-title {
  font-size: 16px;
  font-weight: bold;
  color: #1a428a;
  margin-bottom: 0;
}
</style>
<style lang="scss">
.footerHide {
  background: none !important;
  align-items: center;
  width: 50px;
  font-size: 12px;
}
.footerHide > .v-data-footer__pagination {
  display: none !important;
}
.footerHide > .v-data-footer__icons-before {
  display: none !important;
}
.footerHide > .v-data-footer__icons-after {
  display: none !important;
}
.payment-info .v-data-table > .v-data-table__wrapper > table > tbody > tr > td {
  font-size: 12px;
  vertical-align: baseline;
  padding-top: 6px;
  padding-bottom: 6px;
}
.v-toolbar__title {
  font-size: 16px;
}
.footer-payment {
  margin: 0 auto !important;
}
.footer-showing {
  font-size: 12px !important;
  margin: 0 auto !important;
}

.footerHide > .v-text-field .v-input__prepend-inner,
.v-text-field .v-input__append-inner {
  padding-left: 0 !important;
}
</style>
<style>
/* Hide scrollbar for Chrome, Safari and Opera */
.table-stick-transaction-history .v-data-table__wrapper::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.table-stick-transaction-history .v-data-table__wrapper {
  -ms-overflow-style: none;
  scrollbar-width: none;
}
.super-dense > .v-data-table__wrapper > table > tbody > tr > td,
.super-dense > .v-data-table__wrapper > table > tbody > tr > th,
.super-dense > .v-data-table__wrapper > table > thead > tr > td,
.super-dense > .v-data-table__wrapper > table > thead > tr > th,
.super-dense > .v-data-table__wrapper > table > tfoot > tr > td {
  height: 35px !important;
}
.table-stick-transaction-history .v-data-table__wrapper {
  overflow-x: auto;
  overflow-y: auto;
  height: 400px !important;
}
.table-stick-transaction-history thead {
  position: sticky;
  inset-block-start: 0;
  top: 0;
  background: #ddd !important;
  font-weight: bold;
  color: #000 !important;
  z-index: 5;
}
</style>
<style>
/* Hide scrollbar for Chrome, Safari and Opera */
.table-stick-popup-transaction .v-data-table__wrapper::-webkit-scrollbar {
  height: 8px;
  width: 0px;
  border: 1px solid #fff;
}

.table-stick-popup-transaction .v-data-table__wrapper::-webkit-scrollbar-track {
  border-radius: 0;
  background: #eeeeee;
}

.table-stick-popup-transaction .v-data-table__wrapper::-webkit-scrollbar-thumb {
  border-radius: 0;
  background: #b0b0b0;
}

/* Hide scrollbar for IE, Edge and Firefox */
.table-stick-popup-transaction .v-data-table__wrapper {
  -ms-overflow-style: none;
  scrollbar-width: none;
}
.super-dense > .v-data-table__wrapper > table > tbody > tr > td,
.super-dense > .v-data-table__wrapper > table > tbody > tr > th,
.super-dense > .v-data-table__wrapper > table > thead > tr > td,
.super-dense > .v-data-table__wrapper > table > thead > tr > th,
.super-dense > .v-data-table__wrapper > table > tfoot > tr > td {
  height: 35px !important;
}
.table-stick-popup-transaction .v-data-table__wrapper {
  overflow-x: auto;
  overflow-y: auto;
  height: 350px !important;
}
.table-stick-popup-transaction thead {
  position: sticky;
  inset-block-start: 0;
  top: 0;
  background: #ddd !important;
  font-weight: bold;
  color: #000 !important;
  z-index: 5;
}
</style>
