<template>
  <div class="payment-info">
    <v-data-table
      :options.sync="options"
      :headers="headers"
      :items="depositList"
      class="table-officecare-company"
      :mobile-breakpoint="0"
      hide-default-footer
      :loading="myloadingvariable"
      :items-per-page="-1"
    >
      <template v-slot:top>
        <v-row class="d-flex flex align-center justify-space-between px-0 py-0">
          <v-col cols="8" md="8">
            <v-toolbar-title class="package-title text-capitalize pl-2"
              >{{ $t("page.home.company_depo") }}
            </v-toolbar-title>
          </v-col>
          <v-col cols="4" md="4" class="d-flex justify-end align-center pr-0">
            <v-text-field
                class="searchField fontSet pl-3 pr-3"
                outlined
                dense
                hide-details
                v-model="search"
                append-icon="mdi-magnify"
                :label="$t('search') + ' ...'"
              ></v-text-field>
            <div>
              <v-select
                class="footerHide pt-0"
                v-model="perPage"
                :items="itemShowing"
              ></v-select>
            </div>
          </v-col>
        </v-row>
      </template>
      <template v-slot:item="{ item }">
        <tr v-if="item.billing > 0">
          <td  v-if="item.expired == true" valign="top" class="pt-2 text-center">
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  small
                  @click="editItem(item)"
                  v-bind="attrs"
                  v-on="on"
                  style="color: #00a4b3; padding-right: 10px"
                >
                  mdi-file-document-outline
                </v-icon>
              </template>
              <span>{{ $t("renew_contract") }}</span>
            </v-tooltip>

            <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    small
                    @click="detailItem(item)"
                    v-bind="attrs"
                    v-on="on"
                    style="color: #00a4b3; padding-right: 10px"
                  >
                    mdi-eye
                  </v-icon>
                </template>
                <span>{{ $t("package") }} {{ $t("detail") }}</span>
              </v-tooltip>
          </td>
          <td v-else valign="top" class="pt-2 text-center">
            <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    small
                    @click="detailItem(item)"
                    v-bind="attrs"
                    v-on="on"
                    style="color: #00a4b3; padding-right: 10px"
                  >
                    mdi-eye
                  </v-icon>
                </template>
                <span>{{ $t("package") }} {{ $t("detail") }}</span>
              </v-tooltip>
          </td>
          <td valign="top" class="pt-2">
              <a href="javascript:void(0);" @click="openDialogHistory(item)" style="color: red;">{{ item.name }}</a>
          </td>
          <td valign="top" class="pt-2">
            <p style="color: red; margin-bottom: 0px">
              {{ formatDate(item.start_date) }}
            </p>
          </td>
          <td valign="top" class="pt-2">
            <p style="color: red; margin-bottom: 0px">
              {{ formatDate(item.end_date) }}
            </p>
          </td>
          <td valign="top" class="pt-2" align="right">
            <p style="color: red; margin-bottom: 0px">{{ item.quota }}</p>
          </td>
          <td valign="top" class="pt-2" align="right">
            <p style="color: red; margin-bottom: 0px">{{ item.balance }}</p>
          </td>
          <td valign="top" class="pt-2" align="right">
            <p style="color: red; margin-bottom: 0px">
              {{ formatPrice(item.remaining_balance) }}.00
            </p>
          </td>

          <td valign="top" class="pt-2" align="right">
            <p style="color: red; margin-bottom: 0px">
              {{ formatPrice(item.total_balance) }}.00
            </p>
          </td>

          <td valign="top" class="pt-2" align="right">
            <p style="color: red; margin-bottom: 0px">
              {{ formatPrice(item.billing) }}.00
            </p>
          </td>
        </tr>
        <tr v-else>
          <td  v-if="item.expired == true" valign="top" class="pt-2 text-center">
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  small
                  @click="editItem(item)"
                  v-bind="attrs"
                  v-on="on"
                  style="color: #00a4b3; padding-right: 10px"
                >
                  mdi-file-document-outline
                </v-icon>
              </template>
              <span>{{ $t("renew_contract") }}</span>
            </v-tooltip>

            <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    small
                    @click="detailItem(item)"
                    v-bind="attrs"
                    v-on="on"
                    style="color: #00a4b3; padding-right: 10px"
                  >
                    mdi-eye
                  </v-icon>
                </template>
                <span>{{ $t("package") }} {{ $t("detail") }}</span>
              </v-tooltip>
          </td>
          <td v-else valign="top" class="pt-2 text-center">
            <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    small
                    @click="detailItem(item)"
                    v-bind="attrs"
                    v-on="on"
                    style="color: #00a4b3; padding-right: 10px"
                  >
                    mdi-eye
                  </v-icon>
                </template>
                <span>{{ $t("package") }} {{ $t("detail") }}</span>
              </v-tooltip>
          </td>
          <td valign="top" class="pt-2"> <a href="javascript:void(0);" @click="openDialogHistory(item)" >{{ item.name }}</a></td>
          <td valign="top" class="pt-2">
            {{ formatDate(item.start_date) }}
          </td>
          <td valign="top" class="pt-2">
            {{ formatDate(item.end_date) }}
          </td>
          <td valign="top" class="pt-2" align="right">{{ item.quota }}</td>
          <td valign="top" class="pt-2" align="right">{{ item.balance }}</td>
          <td valign="top" class="pt-2" align="right">
            {{ formatPrice(item.remaining_balance) }}.00
          </td>

          <td valign="top" class="pt-2" align="right">
            {{ formatPrice(item.total_balance) }}.00
          </td>
          <td valign="top" class="pt-2" align="right">
            {{ formatPrice(item.billing) }}.00
          </td>
        </tr>
      </template>

      <template v-slot:footer>
        <v-row
          class="d-flex flex align-center justify-space-between footer-payment"
        >
          <v-col cols="12" md="8">
            <p class="footer-showing">
              showing {{ itemShowStart }} to {{ itemShowStop }} of
              {{ itemsTotal }} entries
            </p>
          </v-col>
          <v-col cols="12" md="4" class="d-flex justify-end">
            <custom-pagination
              @changePage="(val) => (page = val)"
              :totalPage="itemLength"
              :activePage="page"
            />
          </v-col>
        </v-row>
      </template>
    </v-data-table>

    <!-- modal detail transfer deposit-->
    <v-dialog v-model="dialog_detail_deposit" max-width="800px">
      <v-form v-model="valid" lazy-validation ref="form">
        <v-card>
          <v-card-title class="package-title">{{
            $t("field.renewal_contract")
          }}</v-card-title>
          <v-divider class="mt-1"></v-divider>
          <v-card-text style="padding-bottom: 3px !important">
            <v-row>
              <v-col cols="12" md="6" class="field-padding">
                <div class="card-contract-detail">
                  <h3 class="mb-3 mt-2">{{ $t("field.old_contract") }}</h3>
                  <v-text-field
                    class="mt-0 uppercase-input fontSet"
                    v-model="details.name_old"
                    :label="$t('field.contract')"
                    outlined
                    dense
                    disabled
                  ></v-text-field>
                  <v-text-field
                    class="mt-0 uppercase-input fontSet"
                    v-model="details.start_date_old"
                    :label="$t('page.profile.start_date')"
                    outlined
                    disabled
                    dense
                  ></v-text-field>
                  <v-text-field
                    class="mt-0 uppercase-input fontSet"
                    v-model="details.end_date_old"
                    :label="$t('page.profile.end_date')"
                    outlined
                    disabled
                    dense
                  ></v-text-field>
                  <div
                    v-for="(item, index) of details.package_old"
                    :key="index"
                  >
                    <v-text-field
                      class="mt-0 uppercase-input fontSet"
                      v-model="item.package_name"
                      :label="$t('package')"
                      outlined
                      disabled
                      dense
                    ></v-text-field>
                    <v-text-field
                      class="mt-0 uppercase-input fontSet"
                      v-model="item.quota"
                      :label="$t('quota')"
                      outlined
                      disabled
                      dense
                    ></v-text-field>
                  </div>
                </div>
              </v-col>
              <v-col cols="12" md="6" class="field-padding">
                <div class="card-contract-detail">
                  <h3 class="mb-3 mt-2">{{ $t("field.new_contract") }}</h3>
                  <input
                    type="hidden"
                    v-model="payload.corporate_contract_origin_id"
                  />
                  <v-text-field
                    class="mt-0 uppercase-input fontSet"
                    v-model="payload.name"
                    :label="$t('field.contract')"
                    outlined
                    dense
                    :rules="[
                      (v) =>
                        !!v || `${$t('field.contract')} ${$t('is_required')}`,
                    ]"
                  ></v-text-field>

                  <v-menu
                    v-model="menu"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        class="mt-0 uppercase-input fontSet"
                        v-model="dateFormatedStart"
                        :label="$t('page.profile.start_date')"
                        :rules="[
                          (v) =>
                            !!v ||
                            `${$t('page.profile.start_date')} ${$t(
                              'is_required'
                            )}`,
                        ]"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                        hint="DD-MM-YYYY format"
                        outlined
                        dense
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      :locale="$i18n.locale"
                      v-model="payload.start_date"
                      no-title
                      @change="updDate()"
                      @input="menu = false"
                      :min="
                        new Date(
                          Date.now() - new Date().getTimezoneOffset() * 60000
                        )
                          .toISOString()
                          .substr(0, 10)
                      "
                    >
                      <v-spacer></v-spacer>
                    </v-date-picker>
                    <!-- </v-dialog> -->
                  </v-menu>

                  <v-menu
                    v-model="menuEnd"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        class="mt-0 uppercase-input fontSet"
                        v-model="dateFormatedEnd"
                        :label="$t('page.profile.end_date')"
                        :rules="[
                          (v) =>
                            !!v ||
                            `${$t('page.profile.end_date')} ${$t(
                              'is_required'
                            )}`,
                        ]"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                        hint="DD-MM-YYYY format"
                        outlined
                        dense
                        :disabled="disabeldEndDate"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      :locale="$i18n.locale"
                      v-model="payload.end_date"
                      no-title
                      @change="updDateEnd()"
                      @input="menuEnd = false"
                      :min="
                        new Date(
                          new Date(
                            payload.start_date != '' ? payload.start_date : null
                          ) -
                            new Date().getTimezoneOffset() * 60000
                        )
                          .toISOString()
                          .substr(0, 10)
                      "
                    >
                      <v-spacer></v-spacer>
                    </v-date-picker>
                    <!-- </v-dialog> -->
                  </v-menu>

                  <div
                    v-for="(item, i) of payloadPackage"
                    :key="`itemInput-${i}`"
                  >
                    <input type="hidden" v-model="item.package_id" />
                    <v-text-field
                      class="mt-0 uppercase-input fontSet"
                      v-model="item.package_name"
                      :label="$t('package')"
                      outlined
                      disabled
                      dense
                    ></v-text-field>
                    <v-text-field
                      class="mt-0 uppercase-input fontSet"
                      v-model="item.package_quota"
                      :label="$t('quota')"
                      outlined
                      dense
                      :rules="[
                        (v) => !!v || `${$t('quota')} ${$t('is_required')}`,
                      ]"
                    ></v-text-field>
                  </div>
                </div>
              </v-col>
            </v-row>
          </v-card-text>
          <v-divider class="mt-1"></v-divider>
          <v-card-actions class="justify-end">
            <v-btn color="primary darken-1" text @click="closeDetail">  {{ $t("close") }} </v-btn>
            <v-btn text :disabled="!valid" @click="validate">
              {{ $t("save") }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>

      <!-- start modal popup daily referral month (dialog_referral)-->
      <template>
      <v-row justify="center">
        <v-dialog v-model="dialog_referral" width="auto">
          <v-card>
            <v-card-text class="px-5 py-2">
              <!-- <v-row>
                <v-col cols="12" md="12"> -->
                  <div class="payment-info">
                    <v-data-table
                      :headers="headerPackage"
                      :items="payloadPackageDetail"
                      class="table-officecare-company"
                      :class="{
                        'table-stick-popup-transaction':
                        payloadPackageDetail.length > 15,
                      }"
                      :mobile-breakpoint="0"
                      hide-default-footer
                    >
                      <template v-slot:top>
                        <v-card-title class="pa-0">
                          <v-col cols="12" no-gutters>
                            <h4 class="package-title">{{ $t("package") }} {{ $t("detail") }}</h4>
                          </v-col>
                         
                        </v-card-title>
                      </template>
                      <template v-slot:item="{ item }">
                        <tr class="sticky-popup-hdt">
                          <td valign="top" class="pt-2">
                            {{ item.package_name }}
                          </td>
                          <td valign="top" class="pt-2" align="center">
                            {{ item.quota }}
                          </td>
                          <td valign="top" class="pt-2" align="center">
                            {{ item.usage }}
                          </td>
                          <td valign="top" class="pt-2" align="center">
                            {{ item.balance }}
                          </td>
                        </tr>
                      </template>
                     
                    </v-data-table>
                  </div>
                <!-- </v-col>
              </v-row> -->
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="primary darken-1" text @click="closeEdit()">
                {{ $t("close") }}
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-row>
    </template>

     <!-- start modal popup daily referral month (dialog_referral)-->
     <template>
      <v-row justify="center">
        <v-dialog v-model="dialog_history">
          <v-card>
            <v-card-text class="px-5 py-2">
              <!-- <v-row>
                <v-col cols="12" md="12"> -->
                  <div class="payment-info">
                    <v-data-table
                      :headers="headerHistory"
                      :items="datasHistory"
                      class="table-officecare-company"
                      :class="{
                        'table-stick-popup-transaction':
                          datasHistory.length > 15,
                      }"
                      :mobile-breakpoint="0"
                      hide-default-footer
                      :loading="myloadingvariablePopup"
                      :sort-asc="[true]"
                      :items-per-page="-1"
                    >
                      <template v-slot:top>
                        <v-card-title class="pa-0">
                          <v-col cols="12" md="8" no-gutters>
                            <h4 class="package-title">{{ $t("history_renewal") }}</h4>
                          </v-col>
                          <v-col
                            cols="12"
                            md="4"
                            no-gutters
                            class="d-flex align-center justify-end pr-0"
                          >
                            <v-text-field
                              class="searchField fontSet pr-3"
                              outlined
                              dense
                              hide-details
                              v-model="searchPopup"
                              :label="$t('search') + ' ...'"
                              append-icon="mdi-magnify"
                            ></v-text-field>
                            <div>
                              <v-select
                                class="footerHide pt-0"
                                v-model="perPagePopup"
                                :items="itemShowingPopup"
                                hide-details
                              ></v-select>
                            </div>
                          </v-col>
                        </v-card-title>
                      </template>
                      <template v-slot:item="{ item }">
                        <tr class="sticky-popup-hdt">
                          <td valign="top" class="pt-2">
                            {{ item.contract_name }}
                          </td>
                          <td valign="top" class="pt-2" align="center">
                            {{ formatDate(item.start_date)}}
                          </td>
                          <td valign="top" class="pt-2" align="center">
                            {{ formatDate(item.end_date)}}
                          </td>
                          <td valign="top" class="pt-2" align="center">
                            <!-- {{ item.registration_number }} -->
                           {{item.total_quota}}
                          </td>
                          <td valign="top" class="pt-2" align="center">
                            {{ item.level }}
                          </td>
                          <td valign="top" class="pt-2" align="center">
                            {{ item.status }}
                          </td>
                          <td valign="top" class="pt-2">
                            {{ item.reason }}
                          </td>
                          
                        </tr>
                      </template>
                      <template v-slot:footer>
                        <v-row
                          class="d-flex flex align-center justify-space-between footer-payment"
                        >
                          <v-col cols="12" md="8">
                            <p class="footer-showing">
                              showing {{ itemShowStartPopup }} to
                              {{ itemShowStopPopup }} of
                              {{ itemsTotalPopup }} entries
                            </p>
                          </v-col>
                          <v-col cols="12" md="4" class="d-flex justify-end">
                            <custom-pagination
                              @changePage="val => (pagePopup = val)"
                              :totalPage="itemLengthPopup"
                              :activePage="pagePopup"
                          /></v-col>
                        </v-row>
                      </template>
                    </v-data-table>
                  </div>
                <!-- </v-col>
              </v-row> -->
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="primary darken-1" text @click="closeHistory()">
                {{ $t("close") }}
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-row>
    </template>

  </div>
</template>

<script>
import moment from "moment";
import API from "@/service/api_service";
import Auth from "@/service/auth_service";
import CustomPagination from "@/components/v3/pagination.vue";

export default {
  components: {
    CustomPagination,
  },
  data: () => ({
    page: 1,
    pagePopup: 1,
    options: null,
    itemsTotal: 0,
    itemsTotalPopup: 0,
    itemLength: 1,
    itemLengthPopup: 1,
    itemShowStart: 0,
    itemShowStartPopup: 0,
    itemShowStop: 0,
    itemShowStopPopup: 0,
    itemShowing: [
      { value: 25, text: "25" },
      { value: 50, text: "50" },
      { value: 70, text: "70" },
      { value: 100, text: "100" },
    ],
    itemShowingPopup: [
      { value: 25, text: "25" },
      { value: 50, text: "50" },
      { value: 70, text: "70" },
      { value: 100, text: "100" },
    ],
    perPage: 25,
    perPagePopup: 25,
    myloadingvariable: true,
    myloadingvariablePopup: true,
    // myDepoHistoryListCount: 0,
    headers: [],
    depositList: [],

    // checkItemMonth: false,
    // checkItemYear: false,
    search: "",
    searchPopup: "",
    // modalStartDate: false,
    // modalEndDate: false,
    // start_date: "",
    // end_date: "",
    // start_date_filter: "",
    // end_date_filter: "",
    // now: "",
    dialog_detail_deposit: false,
    payloadPackage: [],
    setPayloadPackage: [],
    payload: {
      corporate_contract_origin_id: "",
      name: "",
      start_date: "",
      end_date: "",
    },
    details: {
      name_old: "",
      start_date_old: "",
      end_date_old: "",
      package_old: [],
    },
    menu: false,
    menuEnd: false,
    valid: true,
    disabeldEndDate: true,
    dialog_referral: false,
    dialog_history: false,
    headerPackage: [],
    idTemp: "",
    idTempHistory: "",
    payloadPackageDetail:[],
    datasHistory:[],
    headerHistory:[]
  }),
  computed: {
    dateFormatedStart() {
      return this.formatDateNow(this.payload.start_date);
    },
    dateFormatedEnd() {
      return this.formatDateNow(this.payload.end_date);
    },
  },

  async mounted() {
   
    this.headers = [
      {
        text: this.$t("page.profile.actions"),
        value: "actions",
        sortable: false,
        align: "center",
      },
      {
        text: this.$t("field.contract"),
        value: "name",
        sortable: true,
        align: "center",
      },
      {
        text: this.$t("page.profile.start_date"),
        value: "start_date",
        align: "center",
        sortable: false,
      },
      {
        text: this.$t("page.cart.valid_until"),
        value: "end_date",
        align: "center",
        sortable: false,
      },
      {
        text: this.$t("page.home.total_quota"),
        value: "quota",
        sortable: false,
        align: "center",
      },
      {
        text: this.$t("page.home.remaining_quota"),
        value: "balance",
        sortable: false,
        align: "center",
      },

      {
        text: this.$t("page.home.remaining_balance"),
        value: "remaining_balance",
        sortable: false,
        align: "center",
      },
      {
        text: this.$t("field.total_deposit"),
        value: "total_balance",
        sortable: false,
        align: "center",
      },
      {
        text: this.$t("page.home.billing"),
        value: "billing",
        sortable: false,
        align: "center",
      },
    ];
  },
  created() {
    this.getDataDepositHistory();
    this.headerHistory = [
      {
        text: this.$t("field.contract"),
        align: "center",
        value: "contract_name",
      },
      {
        text: this.$t("page.profile.start_date"),
        value: "start_date",
        sortable: false,
        align: "center",
      },
      {
        text: this.$t("page.profile.end_date"),
        value: "end_date",
        sortable: false,
        align: "center",
      },
      {
        text: this.$t("total_quota"),
        value: "total_quota",
        align: "center",
        sortable: false,
      },
      {
        text: this.$t("field.level"),
        sortable: false,
        value: "level",
        align: "center",
      },
      {
        text: this.$t("page.profile.status"),
        sortable: false,
        value: "status",
        align: "center",
      },
      {
        text: this.$t("page.profile.notes"),
        value: "reason",
        sortable: false,
        align: "center",
      },
    
    ];
    this.headerPackage = [
      {
        text: this.$t("package"),
        align: "center",
        value: "package_name",
      },
      {
        text: this.$t("total_quota"),
        value: "quota",
        sortable: false,
        align: "center",
      },
      {
        text: this.$t("quota_usage"),
        value: "usage",
        align: "center",
        sortable: false,
      },
      {
        text: this.$t("quota_balance"),
        sortable: false,
        value: "balance",
        align: "center",
      },
      
    ];

  },
  watch: {
    page: function () {
      this.getDataDepositHistory();
    },
    perPage: function () {
      if (this.page != 1) {
        this.page = 1;
      } else {
        this.getDataDepositHistory();
      }
    },
    pagePopup: function () {
      this.getHistoryRequest(this.idTempHistory);
    },
    perPagePopup: function () {
      if (this.pagePopup != 1) {
        this.pagePopup = 1;
      } else {
        this.getHistoryRequest(this.idTempHistory);
      }
    },
    search: function () {
      this.page = 1;
      this.getDataDepositHistory();
    },
    searchPopup: function () {
      this.pagePopup = 1;
      this.getHistoryRequest(this.idTempHistory);
    },
  },
  methods: {
    async openDialogHistory(item) {
      this.getHistoryRequest(item.id);
      this.dialog_history = true;
    },
    async getHistoryRequest(getDatas) {
      this.idTempHistory = getDatas;
      try {
        this.myloadingvariablePopup = true;
        // pagination showing start of entries
        let startPagePopup = (this.pagePopup - 1) * this.perPagePopup;
        let showPagePopup = this.perPagePopup;
        this.itemShowStartPopup = startPagePopup + 1;
        this.itemShowStopPopup = startPagePopup + showPagePopup;

        const res = await API.get(
          `${process.env.VUE_APP_API_TRUST}business-partner-contract/get-history?query=${this.searchPopup}&bp_contract_id=${getDatas}&offset=${startPagePopup}&per_page=${showPagePopup}`,
          {
            "Content-Type": "application/json",
            Authorization: `Bearer ${Auth.getToken()}`,
          }
        );

        // Check result exist
        if (res.results) {
          var new_data = [];
          res.results.map(function (e) {
            var endDateFormat = "";
            if (e.end_date !== null) {
              endDateFormat = moment(e.end_date)
                .format("DD-MMM-YYYY")
                .toUpperCase();
            } else {
              endDateFormat = "-";
            }

            var startDateFormat = "";
            if (e.start_date !== null) {
              startDateFormat = moment(e.start_date)
                .format("DD-MMM-YYYY")
                .toUpperCase();
            } else {
              startDateFormat = "-";
            }

            var statusApproval = "";
            if(e.approval_status == false && e.rejected_status == false){
              // statusApproval = this.$t("waiting_for_approval");
              statusApproval = "Waiting Approval";
            } else if(e.approval_status == false && e.rejected_status == true){
              // statusApproval = this.$t("reject");
              statusApproval = "Rejected";
            } else if(e.approval_status == true && e.rejected_status == false){
              // statusApproval = this.$t("approve");
              statusApproval = "Approved";
            } else{
              statusApproval = "-";
            }


            new_data.push({
              contract_name:
                e.contract_name !== null
                  ? e.contract_name.toUpperCase()
                  : "-",
              start_date: startDateFormat,
              end_date: endDateFormat,
              level:
              e.level !== null ? e.level.toUpperCase() : "-",
              total_quota:
                e.total_quota !== null ? e.total_quota : "-",
                status: statusApproval.toUpperCase(),
                reason:
                  e.reason !== null ? e.reason.toUpperCase() : "-",
              
            });
          });

          this.datasHistory = new_data;

          this.itemsTotalPopup = res.total;
          this.itemLengthPopup = Math.ceil(
            this.itemsTotalPopup / showPagePopup
          );
          if (this.itemShowStopPopup < 0) {
            this.itemShowStopPopup = res.total;
          }
          if (this.itemShowStopPopup > res.total) {
            this.itemShowStopPopup = res.total;
          }
          this.myloadingvariablePopup = false;
        }
      } catch (error) {
        console.log(error);
      }
    },

   async detailItem(item) {
      this.getDetailPackages(item.id);
      this.dialog_referral = true;
    },
    updDate() {
      this.disabeldEndDate = false;
      this.$store.state.date = moment(this.dateFormatedStart)
        .format("YYYY-MM-DD")
        .toUpperCase();
    },
    updDateEnd() {
      this.$store.state.date = moment(this.dateFormatedEnd)
        .format("YYYY-MM-DD")
        .toUpperCase();
    },
    async getDataDepositHistory() {
      try {
        this.myloadingvariable = true;
        // pagination showing start of entries
        let startPage = (this.page - 1) * this.perPage;
        let showPage = this.perPage;
        this.itemShowStart = startPage + 1;
        this.itemShowStop = startPage + showPage;

        const res = await API.get(
          `${process.env.VUE_APP_API_TRUST}employer/get-contract?query=${this.search}&offset=${startPage}&per_page=${showPage}`,
          {
            "Content-Type": "application/json",
            Authorization: `Bearer ${Auth.getToken()}`,
          }
        );
        if (res.statusCode == 200) {
          this.depositList = res.results;
        }

        this.itemsTotal = res.total;
        this.itemLength = Math.ceil(this.itemsTotal / showPage);
        if (this.itemShowStop < 0) {
          this.itemShowStop = res.total;
        }
        if (this.itemShowStop > res.total) {
          this.itemShowStop = res.total;
        }
        this.myloadingvariable = false;
      } catch (error) {
        console.log(error);
      }
    },
    // setPageMyDepoHistory(data) {
    //   this.myDepoHistoryListCount = data.pageStop - data.pageStart;
    // },

    async editItem(item) {
      this.getDetailDeposit(item.id);
      this.dialog_detail_deposit = true;
    },

    async getDetailPackages(getDatasDetail) {
      this.payloadPackageDetail = [];
      try {
        const res = await API.get(
          `${process.env.VUE_APP_API_TRUST}employer/get-contract`,
          {
            "Content-Type": "application/json",
            Authorization: `Bearer ${Auth.getToken()}`,
          }
        );

        // Check result exist
        if (res.results) {
          const filterNewGesDetail = res.results.filter((dataNewGesDetail) => {
            return dataNewGesDetail.id == getDatasDetail;
          });

          this.payloadPackageDetail = filterNewGesDetail[0].details;
         
        }
      } catch (error) {
        console.log(error);
      }
    },

    async getDetailDeposit(getDatas) {
      this.payloadPackage = [];
      try {
        const res = await API.get(
          `${process.env.VUE_APP_API_TRUST}employer/get-contract`,
          {
            "Content-Type": "application/json",
            Authorization: `Bearer ${Auth.getToken()}`,
          }
        );

        // Check result exist
        if (res.results) {
          const filterNewGes = res.results.filter((dataNewGes) => {
            return dataNewGes.id == getDatas;
          });

          this.details.name_old = filterNewGes[0].name;
          this.details.start_date_old = moment(filterNewGes[0].start_date)
            .format("DD-MMM-YYYY")
            .toUpperCase();
          this.details.end_date_old = moment(filterNewGes[0].end_date)
            .format("DD-MMM-YYYY")
            .toUpperCase();

          this.payload.corporate_contract_origin_id = filterNewGes[0].id;

          this.details.package_old = filterNewGes[0].details;
          this.details.package_old.forEach((element) => {
            this.payloadPackage = [
              ...this.payloadPackage,
              {
                package_id: element.package_id,
                package_name: element.package_name,
                package_quota: "",
              },
            ];
          });
        }
      } catch (error) {
        console.log(error);
      }
    },

    closeDetail() {
      this.dialog_detail_deposit = false;
    },
    closeEdit() {
      this.dialog_referral = false;
      this.idTemp = "";
    },
    closeHistory() {
      this.dialog_history = false;
      this.idTempHistory = "";
    },

    formatDate(date) {
      return moment(date)
        .locale(this.$i18n.locale)
        .format("DD-MMM-YYYY")
        .toUpperCase();
    },
    formatPrice(price) {
      return Intl.NumberFormat().format(price);
    },

    formatDateNow(param) {
      if (!param) return null;
      return moment(param)
        .locale(this.$i18n.locale)
        .format("DD-MMM-YYYY")
        .toUpperCase();
    },

    validate() {
      if (this.$refs.form.validate() == true) {
        if (this.payloadPackage.length > 0) {
          this.payloadPackage.forEach((element) => {
            this.setPayloadPackage = [
              ...this.setPayloadPackage,
              {
                package_id: element.package_id,
                quota: element.package_quota,
              },
            ];
          });
        } else {
          this.setPayloadPackage = [];
        }

        const { corporate_contract_origin_id, name, start_date, end_date } =
          this.payload;

        let dataReady = {};

        dataReady = {
          corporate_contract_origin_id: corporate_contract_origin_id,
          name: name,

          start_date: start_date,
          end_date: end_date,
          packages: this.setPayloadPackage,
        };

        API.post(
          `${process.env.VUE_APP_API_TRUST}business-partner-contract/renewal`,
          {
            "Content-Type": "application/json",
            Authorization: `Bearer ${Auth.getToken()}`,
          },
          [dataReady]
        ).then((res) => {
          if (res.statusCode === 201) {
            this.$router.push(
              `/${this.$i18n.locale}/company/deposit-transaction`
            );
            window.location.href = `/${this.$i18n.locale}/company/deposit-transaction`;
            this.toast("success", this.$t("success_insert_data"));
          }
        });
      }
    },
  },
};
</script>
<style>
/* Hide scrollbar for Chrome, Safari and Opera */
.table-stick-deposit-history .v-data-table__wrapper::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.table-stick-deposit-history .v-data-table__wrapper {
  -ms-overflow-style: none;
  scrollbar-width: none;
}
.super-dense > .v-data-table__wrapper > table > tbody > tr > td,
.super-dense > .v-data-table__wrapper > table > tbody > tr > th,
.super-dense > .v-data-table__wrapper > table > thead > tr > td,
.super-dense > .v-data-table__wrapper > table > thead > tr > th,
.super-dense > .v-data-table__wrapper > table > tfoot > tr > td {
  height: 35px !important;
}
.table-stick-deposit-history .v-data-table__wrapper {
  overflow-x: auto;
  overflow-y: auto;
  height: 350px !important;
}
.table-stick-deposit-history thead {
  position: sticky;
  inset-block-start: 0;
  top: 0;
  background: #ddd !important;
  font-weight: bold;
  color: #000 !important;
  z-index: 5;
}
</style>
<style>
.uppercase-input {
  text-transform: uppercase;
}
</style>
<style lang="scss" scoped>
@import "@/assets/scss/color.scss";
.theme--light.v-data-table {
  background-color: transparent !important;
}
.content-table {
  padding: 15px;
  @media screen and (max-width: 960px) {
    padding: 5px 18px 18px 18px;
  }
}
@media screen and (max-width: 480px) {
  .content-table {
    padding: 0px !important;
  }
}
.searchField {
  max-width: 500px;
}
.center-empty {
  display: flex;
  justify-content: center;
  align-items: center;
}
.tab-switch {
  color: $main_2;
  margin-right: 8px;
}
.td {
  font-size: 12px;
}
.h4 {
  color: $main_2;
}
.list-data {
  border-bottom: 1px solid $gray_2;
  padding: 8px;
  height: 100%;
}
.pointer {
  cursor: pointer;
}
.card-chart {
  position: relative;
}
.text-title {
  font-size: 12px;
  text-transform: uppercase;
  color: $main_2;
  font-weight: bold;
}
.text-value {
  font-size: 12px;
  text-transform: uppercase;
  color: #000;
  text-align: right;
}

.v-tabs {
  width: 720px;
}

.center-chart {
  background: transparent;
  height: 80%;
  width: 80%;
  left: 50%;
  top: 52%;
  transform: translate(-50%, -50%);
  border-radius: 50%;
  position: absolute;
  z-index: 99;
  display: flex;
  justify-content: center;
  align-items: center;
  p {
    font-size: 72px;
    margin-bottom: 0;
  }
}
.label-doughnut {
  align-self: center;
}
.doughnut-title {
  padding: 4px;
}
.chart-title {
  padding: 4px 12px;
}

.v-input__control {
  height: 30px;
}
.v-input__slot {
  height: 30px;
}

.v-select__selections {
  width: 30px;
  height: 30px;
}

.sticky-table-footer {
  td {
    font-weight: bold;
  }
}

.card-chart .v-data-table {
  td {
    font-size: 12px;
  }
}

.table-patient {
  th {
    .text-gray {
      color: $main_2;
      margin: 0;
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 20px;
    }
  }
  td {
    .text-gray {
      color: $gray_table;
      margin: 0;
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
    }
    .text-black {
      color: $black_table;
      margin: 0;
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 20px;
    }
  }
}

.v-data-table > .v-data-table__wrapper > table > tbody > tr > td {
  font-size: 12px;
  vertical-align: baseline;
  padding-top: 6px;
  padding-bottom: 6px;
  height: 25px !important;
}

.v-data-table > .v-data-table__wrapper > table > thead > tr > th {
  height: 35px !important;
}
.fontSet {
  font-size: 12px !important;
}
.button-box {
  box-sizing: border-box;
  border-radius: 10px;
  padding: 8px 16px;
  outline: none;
  text-transform: capitalize;
}
.button--active {
  background: #00a4b3 !important;
  color: white;
}
.head-vir {
  background: #1a428a;
  color: #fff;
}

.head-vir2 {
  background: #6dc6d6;
  font-weight: bold;
}

.head-vir2:hover {
  background: #6dc6d6 !important;
}

.head-vir2 th {
  color: #000 !important;
}
.slider {
  background: $gray_7;
  height: 12px;
  border-radius: 6px;
  overflow: hidden;
  .value {
    background: $main_1;
    height: 100%;
    border-radius: 6px;
  }
}
.value-detail {
  color: $gray_4;
  font-size: 14px;
  margin-bottom: 0;
}
.label-detail {
  font-size: 12px;
  color: $gray_8;
  margin-bottom: 0;
}
.package-title {
  font-size: 16px;
  font-weight: bold;
  color: $dark_blue_2;
  margin-bottom: 0;
}
</style>
<style lang="scss">
.footerHide {
  background: none !important;
  align-items: center;
  width: 50px;
  font-size: 12px;
}
.footerHide > .v-data-footer__pagination {
  display: none !important;
}
.footerHide > .v-data-footer__icons-before {
  display: none !important;
}
.footerHide > .v-data-footer__icons-after {
  display: none !important;
}
.payment-info .v-data-table > .v-data-table__wrapper > table > tbody > tr > td {
  font-size: 12px;
  vertical-align: baseline;
  padding-top: 6px;
  padding-bottom: 6px;
}
.v-toolbar__title {
  font-size: 16px;
}
.footer-payment {
  margin: 0 auto !important;
}
.footer-showing {
  font-size: 12px !important;
  margin: 0 auto !important;
}

.footerHide > .v-text-field .v-input__prepend-inner,
.v-text-field .v-input__append-inner {
  padding-left: 0 !important;
}
</style>
