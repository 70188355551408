<template>
  <div class="payment-info">
      <v-data-table
        :options.sync="options"
        :headers="headers"
        :items="datas"
        class="table-officecare-company"
        :mobile-breakpoint="0"
        hide-default-footer
        :loading="myloadingvariable"
        :items-per-page="-1"
        :custom-sort="customSort"
      >
        <template v-slot:top>
          <v-row
            class="d-flex flex align-center justify-space-between px-0 py-0"
          >
            <v-col cols="12" md="8" class="d-flex align-center pl-6">
              <v-btn
                color="primary"
                dark
                class="button"
                @click="() => addTransfer()"
              >
                {{ $t("add") }}
              </v-btn>
            </v-col>
            <v-col cols="12" md="4" class="d-flex justify-end align-center pr-0">
              <v-text-field
                class="searchField fontSet pl-3 pr-3"
                outlined
                dense
                hide-details
                v-model="search"
                :label="$t('search') + ' ...'"
                append-icon="mdi-magnify"
              ></v-text-field>
      
              <div >
                <v-select
                  class="footerHide"
                  v-model="perPage"
                  :items="itemShowing"
                ></v-select>
              </div>
            </v-col>
          </v-row>
        </template>
        <template v-slot:item="{ item }">
          <tr>
            <td valign="top" class="pt-2 text-center">
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    small
                    @click="editItem(item)"
                    v-bind="attrs"
                    v-on="on"
                    style="color: #00a4b3; padding-right: 10px"
                  >
                    mdi-eye
                  </v-icon>
                </template>
                <span>{{ $t("detail") }}</span>
              </v-tooltip>
            </td>
            <td valign="top" class="pt-2">{{ item.transfer_no }}</td>
            <td valign="top" class="pt-2">
              {{ formatDate(item.booking_date) }}
            </td>
            <td valign="top" class="pt-2">{{ item.contract_name_old }}</td>
            <td valign="top" class="pt-2">{{ item.contract_name_new }}</td>
            <td valign="top" class="pt-2">
              <p class="list-table text-right" style="margin-bottom: 0px">
                {{ formatPrice(item.transfer_value) }}
              </p>
            </td>
            <td v-if="item.status == true" valign="top" class="pt-2">Paid</td>
            <td v-else valign="top" class="pt-2">Unpaid</td>
          </tr>
        </template>
        <template v-slot:footer>
          <v-row
            class="d-flex flex align-center justify-space-between footer-payment"
            
          >
            <v-col cols="12" md="8">
              <p class="footer-showing">
                showing {{ itemShowStart }} to {{ itemShowStop }} of
                {{ itemsTotal }} entries
              </p>
            </v-col>
            <v-col cols="12" md="4" class="d-flex justify-end">
              <custom-pagination @changePage="val => page = val" :totalPage="itemLength" :activePage="page" />
            </v-col>
          </v-row>
        </template>
      </v-data-table>

    <!-- modal transfer add -->
    <v-dialog v-model="dialogTransferAdd" max-width="800px">
      <v-form ref="formReject" lazy-validation>
        <v-card>
          <v-card-title class="package-title">{{ $t("field.transfer_form") }}</v-card-title>
          <v-divider class="mt-1"></v-divider>
          <v-card-text style="padding-bottom: 3px !important">
            <v-row>
              <v-col cols="12" md="6" class="field-padding">
                <div class="card-contract">
                  <h3 class="mb-3 mt-2">{{ $t("field.old_contract") }}</h3>
                  <v-select
                    outlined
                    dense
                    class="mt-0"
                    v-model="payload.old_contract"
                    :items="oldContract"
                    item-text="name"
                    item-value="id"
                    :rules="[
                      (v) =>
                        !!v || `${$t('field.contract')} ${$t('is_required')}`,
                    ]"
                    :label="$t('field.contract')"
                    required
                    @change="selectOldContract(payload.old_contract)"
                  ></v-select>
                  <v-text-field
                    class="mt-0 uppercase-input fontSet"
                    v-model="payload.expired_at_old"
                    :rules="[
                      (v) =>
                        !!v ||
                        `${$t('page.profile.expired_at')} ${$t('is_required')}`,
                    ]"
                    :label="$t('page.profile.expired_at')"
                    outlined
                    required
                    disabled
                    dense
                  ></v-text-field>
                  <v-text-field
                    class="mt-0 uppercase-input fontSet"
                    v-model="payload.balance_deposit_old"
                    :rules="[
                      (v) =>
                        !!v ||
                        `${$t('field.balance_deposit')} ${$t('is_required')}`,
                    ]"
                    :label="$t('field.balance_deposit')"
                    outlined
                    required
                    disabled
                    dense
                  ></v-text-field>
                  <v-text-field
                    class="mt-0 uppercase-input fontSet"
                    v-model="payload.transfer_deposit_old"
                    :rules="[
                      (v) =>
                        !!v ||
                        `${$t('field.transfer_deposit')} ${$t('is_required')}`,
                    ]"
                    :label="$t('field.transfer_deposit')"
                    outlined
                    required
                    disabled
                    dense
                  ></v-text-field>
                </div>
              </v-col>
              <v-col cols="12" md="6" class="field-padding">
                <div class="card-contract">
                  <h3 class="mb-3 mt-2">{{ $t("field.new_contract") }}</h3>
                  <v-select
                    outlined
                    dense
                    class="mt-0"
                    v-model="payload.new_contract"
                    :items="newContract"
                    item-text="name"
                    item-value="id"
                    :rules="[
                      (v) =>
                        !!v || `${$t('field.contract')} ${$t('is_required')}`,
                    ]"
                    :label="$t('field.contract')"
                    required
                    :disabled="disabledContract"
                    @change="selectNewContract(payload.new_contract)"
                  ></v-select>
                  <v-text-field
                    class="mt-0 uppercase-input fontSet"
                    v-model="payload.expired_at_new"
                    :rules="[
                      (v) =>
                        !!v ||
                        `${$t('page.profile.expired_at')} ${$t('is_required')}`,
                    ]"
                    :label="$t('page.profile.expired_at')"
                    outlined
                    required
                    dense
                    disabled
                  ></v-text-field>
                  <v-text-field
                    class="mt-0 uppercase-input fontSet"
                    v-model="payload.balance_deposit_new"
                    :rules="[
                      (v) =>
                        !!v ||
                        `${$t('field.balance_deposit')} ${$t('is_required')}`,
                    ]"
                    :label="$t('field.balance_deposit')"
                    outlined
                    required
                    dense
                    disabled
                  ></v-text-field>
                  <v-text-field
                    class="mt-0 uppercase-input fontSet"
                    v-model="payload.total_deposit"
                    :rules="[
                      (v) =>
                        !!v ||
                        `${$t('field.total_deposit')} ${$t('is_required')}`,
                    ]"
                    :label="$t('field.total_deposit')"
                    outlined
                    required
                    dense
                    disabled
                  ></v-text-field>
                </div>
              </v-col>
            </v-row>
          </v-card-text>
          <v-divider class="mt-1"></v-divider>
          <v-card-actions class="justify-end">
            <v-btn text @click="closeEdit"> Cancel </v-btn>
            <v-btn color="#00a4b3" text @click="saveTransfer"> Save </v-btn>
          </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>

    <!-- modal detail transfer deposit-->
    <v-dialog v-model="dialog_detail_deposit" max-width="800px">
      <v-card>
        <v-card-title class="package-title">{{ $t("field.transfer_detail") }}</v-card-title>
        <v-divider class="mt-1"></v-divider>
        <v-card-text style="padding-bottom: 3px !important">
          <v-row>
            <v-col cols="12" md="6" class="field-padding">
              <div class="card-contract-detail">
                <h3 class="mb-3 mt-2">{{ $t("field.old_contract") }}</h3>
                <v-text-field
                  class="mt-0 uppercase-input fontSet"
                  v-model="details.old_contract"
                  :label="$t('field.contract')"
                  outlined
                  dense
                  disabled
                ></v-text-field>
                <v-text-field
                  class="mt-0 uppercase-input fontSet"
                  v-model="details.expired_at_old"
                  :label="$t('page.profile.expired_at')"
                  outlined
                  disabled
                  dense
                ></v-text-field>
                <v-text-field
                  class="mt-0 uppercase-input fontSet"
                  v-model="details.balance_deposit_old"
                  :label="$t('field.balance_deposit')"
                  outlined
                  disabled
                  dense
                ></v-text-field>
                <v-text-field
                  class="mt-0 uppercase-input fontSet"
                  v-model="details.transfer_deposit_old"
                  :label="$t('field.transfer_deposit')"
                  outlined
                  disabled
                  dense
                ></v-text-field>
              </div>
            </v-col>
            <v-col cols="12" md="6" class="field-padding">
              <div class="card-contract-detail">
                <h3 class="mb-3 mt-2">{{ $t("field.new_contract") }}</h3>
                <v-text-field
                  class="mt-0 uppercase-input fontSet"
                  v-model="details.new_contract"
                  :label="$t('field.contract')"
                  outlined
                  dense
                  disabled
                ></v-text-field>
                <v-text-field
                  class="mt-0 uppercase-input fontSet"
                  v-model="details.expired_at_new"
                  :label="$t('page.profile.expired_at')"
                  outlined
                  dense
                  disabled
                ></v-text-field>
                <v-text-field
                  class="mt-0 uppercase-input fontSet"
                  v-model="details.balance_deposit_new"
                  :label="$t('field.balance_deposit')"
                  outlined
                  dense
                  disabled
                ></v-text-field>
                <v-text-field
                  class="mt-0 uppercase-input fontSet"
                  v-model="details.total_deposit"
                  :label="$t('field.total_deposit')"
                  outlined
                  dense
                  disabled
                ></v-text-field>
              </div>
            </v-col>
          </v-row>
        </v-card-text>
        <v-divider class="mt-1"></v-divider>
        <v-card-actions class="justify-end">
          <v-btn text @click="closeDetail"> Cancel </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import API from "@/service/api_service.js";
import Auth from "@/service/auth_service";
import moment from "moment";
import CustomPagination from "@/components/v3/pagination.vue";

export default {
  components: {  CustomPagination },
  data: () => ({
    page: 1,
    options: null,
    itemsTotal: 0,
    itemLength: 1,
    itemShowStart: 0,
    itemShowStop: 0,
    itemShowing: [
      { value: 25, text: "25" },
      { value: 50, text: "50" },
      { value: 70, text: "70" },
      { value: 100, text: "100" },
    ],
    perPage: 25,
    myloadingvariable: true,
    myDepoTransferListCount: 0,
    showNavbar: true,
    showCart: false,
    showCartDesktop: false,
    showSearch: false,
    showLogo: true,
    search: "",
    searchPopup: "",
    dialogDelete: false,
    formHasError: false,
    headers: [],
    datas: [],
    dialog_detail_deposit: false,
    dialog_invoice_batch: false,
    editedIndex: -1,
    registration_group: null,
    kode: null,
    booking_date: null,
    status: null,
    disabledContract: true,
    errorMessages: "",

    getAuth: null,

    searchData: "",
    dialogTransferAdd: false,
    companyCode: "",
    payload: {
      old_contract: "",
      expired_at_old: "",
      balance_deposit_old: "",
      transfer_deposit_old: "",
      new_contract: "",
      expired_at_new: "",
      balance_deposit_new: "",
      total_deposit: "",
      transfer_deposit: "",
    },
    details: {
      old_contract: "",
      expired_at_old: "",
      balance_deposit_old: "",
      transfer_deposit_old: "",
      new_contract: "",
      expired_at_new: "",
      balance_deposit_new: "",
      total_deposit: "",
    },
    oldContract: [],
    newContract: [],
    setInput: [],
  }),
  created() {
    this.headers = [
      {
        text: this.$t("page.profile.actions"),
        value: "actions",
        sortable: false,
        align: "center",
      },
      {
        text: this.$t("field.deposit_number"),
        align: "center",
        value: "transfer_no",
        sortable: false,
      },
      {
        text: this.$t("date"),
        value: "booking_date",
        sortable: true,
        align: "center",
      },
      {
        text: this.$t("field.old_contract"),
        value: "contract_name_old",
        sortable: false,
        align: "center",
      },
      {
        text: this.$t("field.new_contract"),
        value: "contract_name_new",
        sortable: false,
        align: "center",
      },
      {
        text: this.$t("field.transfer_value"),
        value: "transfer_value",
        sortable: false,
        align: "center",
      },
      {
        text: this.$t("page.profile.status"),
        value: "status",
        sortable: false,
        align: "center",
      },
    ];
    this.getDataEmployee();
  },
  mounted() {
    this.getAuth = Auth.getTypeUser();
    // old contract
    API.get(
      `${process.env.VUE_APP_API_TRUST}business-partner-contract/get?expired=true`,
      {
        "Content-Type": "application/json",
        Authorization: `Bearer ${Auth.getToken()}`,
      }
    ).then((res) => {
      if (res.statusCode == 200) {
        this.oldContract = res.results.sort((a, b) => this.sortByName(a, b));
      }
    });
  },
  watch: {
    dialogDelete(val) {
      val || this.closeDelete();
    },
    page: function () {
      this.getDataEmployee();
    },
    search: function () {
      this.page = 1;
      this.getDataEmployee();
    },
    perPage: function () {
      if (this.page != 1) {
        this.page = 1;
      } else {
        this.getDataEmployee();
      }
    },
  },

  methods: {
    customSort(items, index, isDesc) {
      items.sort((a, b) => {
          if (index[0]=='booking_date') {
            if (!isDesc[0]) {
                return new Date(b[index]) - new Date(a[index]);
            } else {
                return new Date(a[index]) - new Date(b[index]);
            }
          }
          else {
            if(typeof a[index] !== 'undefined'){
              if (!isDesc[0]) {
                 return a[index].toLowerCase().localeCompare(b[index].toLowerCase());
              }
              else {
                  return b[index].toLowerCase().localeCompare(a[index].toLowerCase());
              }
            }
          }
      });
      return items;
    },
    async selectOldContract(param) {
      try {
        const res = await API.get(
          `${process.env.VUE_APP_API_TRUST}business-partner-contract/get?expired=true`,
          {
            "Content-Type": "application/json",
            Authorization: `Bearer ${Auth.getToken()}`,
          }
        );

        // Check result exist
        if (res.results) {
          const filterOldContract = res.results.filter((oldDataContract) => {
            return oldDataContract.id == param;
          });

          if (filterOldContract[0].balance_amount == 0) {
            this.$swal({
              text: this.$t("field.warning_balance_amount"),
              showCancelButton: false,
              confirmButtonColor: "#1A428A",
              confirmButtonText: this.$t("ok"),
            });
            this.payload.old_contract = "";
            this.payload.expired_at_old = "";
            this.payload.balance_deposit_old = "";
            this.payload.transfer_deposit_old = "";
            this.payload.transfer_deposit = "";
            this.disabledContract = true;
          } else {
            this.payload.expired_at_old = moment(filterOldContract[0].end_date)
              .format("DD-MMM-YYYY")
              .toUpperCase();
            this.payload.balance_deposit_old = Intl.NumberFormat().format(
              filterOldContract[0].balance_amount
            );
            this.payload.transfer_deposit_old = Intl.NumberFormat().format(
              filterOldContract[0].balance_amount
            );
            this.payload.transfer_deposit = filterOldContract[0].balance_amount;

            API.get(
              `${process.env.VUE_APP_API_TRUST}business-partner-contract/get?available=true`,
              {
                "Content-Type": "application/json",
                Authorization: `Bearer ${Auth.getToken()}`,
              }
            ).then((res) => {
              if (res.statusCode == 200) {
                this.newContract = res.results.sort((a, b) =>
                  this.sortByName(a, b)
                );
              }
            });

            this.disabledContract = false;
          }
        }
      } catch (error) {
        console.log(error);
      }
    },
    async selectNewContract(param) {
      try {
        const res = await API.get(
          `${process.env.VUE_APP_API_TRUST}business-partner-contract/get?available=true`,
          {
            "Content-Type": "application/json",
            Authorization: `Bearer ${Auth.getToken()}`,
          }
        );

        // Check result exist
        if (res.results) {
          const filterNewContract = res.results.filter((newDataContract) => {
            return newDataContract.id == param;
          });

          var totalDepositAdd =
            parseInt(filterNewContract[0].balance_amount) +
            parseInt(this.payload.transfer_deposit);

          this.payload.expired_at_new = moment(filterNewContract[0].end_date)
            .format("DD-MMM-YYYY")
            .toUpperCase();
          this.payload.balance_deposit_new = Intl.NumberFormat().format(
            filterNewContract[0].balance_amount
          );
          this.payload.total_deposit =
            Intl.NumberFormat().format(totalDepositAdd);
        }
      } catch (error) {
        console.log(error);
      }
    },
    addTransfer() {
      this.dialogTransferAdd = true;
    },
    async saveTransfer() {
      if (this.$refs.formReject.validate() == true) {
        this.setInput = [
          ...this.setInput,
          {
            contract_id_old: this.payload.old_contract,
            contract_id_new: this.payload.new_contract,
            amount: parseInt(this.payload.transfer_deposit),
            client_unique_id: "tm617a34c4a3b86",
          },
        ];
        var setParamData = this.setInput[0];

        API.post(
          `${process.env.VUE_APP_API_TRUST}business-partner-contract/transfer`,
          {
            "Content-Type": "application/json",
            Authorization: `Bearer ${Auth.getToken()}`,
          },
          setParamData
        )
          .then((res) => {
            if (res.statusCode === 200) {
              window.location.href = `/${this.$i18n.locale}/company/deposit-transaction?tab=transfer`;
            }
            // else if (res.statusCode == 400) {
            //   Object.keys(res.errors).forEach((element) => {
            //     this.toast(
            //       "error",
            //       typeof res.errors[element] == "object"
            //         ? res.errors[element][0]
            //         : res.errors[element]
            //     );
            //   });
            //   this.setInput = [];
            // }
            // else if (res.statusCode == 403) {
            //   // this.toast("error", res.message);
            //   Object.keys(res.errors).forEach((element) => {
            //     this.toast(
            //       "error",
            //       typeof res.errors[element] == "object"
            //         ? res.errors[element][0]
            //         : res.errors[element]
            //     );
            //   });
            //   this.setInput = [];
            // }
            else {
              this.$swal({
                icon: "error",
                text: res.message,
              });
            }
          })
          .catch(() => {
            this.$swal({
              icon: "error",
              text: "Error Add Transfer Deposit!",
            });
          });
      }
    },
    closeEdit() {
      this.errorMessages = "";
      this.formHasErrors = false;
      this.dialogTransferAdd = false;
      // this.selectedAccount = this.accountActive;
      this.$nextTick(() => {
        this.editedIndex = -1;
      });
    },
    closeDetail() {
      this.dialog_detail_deposit = false;
    },
    setPageMyDepoTransfer(data) {
      this.myDepoTransferListCount = data.pageStop - data.pageStart;
    },
    formatPrice(price) {
      return Intl.NumberFormat().format(price);
    },
    formatDate(date) {
      if (!date) return null;
      return moment(date)
        .locale(this.$i18n.locale)
        .format("DD-MMM-YYYY")
        .toUpperCase();
    },
    sortByName(a, b) {
      if (a.name < b.name) {
        return -1;
      }
      if (a.name > b.name) {
        return 1;
      }
      return 0;
    },
    async editItem(item) {
      this.getDetailDeposit(item.id);
      this.dialog_detail_deposit = true;
    },

    async getDetailDeposit(getDatas) {
      try {
        const res = await API.get(
          `${process.env.VUE_APP_API_TRUST}business-partner-contract/list-transfer`,
          {
            "Content-Type": "application/json",
            Authorization: `Bearer ${Auth.getToken()}`,
          }
        );

        // Check result exist
        if (res.results) {
          const filterNewGes = res.results.filter((dataNewGes) => {
            return dataNewGes.id == getDatas;
          });
          var setBalanceDepositOld =
            parseInt(filterNewGes[0].balance_amount_old) +
            parseInt(filterNewGes[0].transfer_value);
          var setBalanceDepositNew =
            parseInt(filterNewGes[0].balance_amount_new) -
            parseInt(filterNewGes[0].transfer_value);
          // var totalDepositSet =  parseInt(filterNewGes[0].balance_amount_new) + parseInt(filterNewGes[0].transfer_value );
          var totalDepositSet = parseInt(filterNewGes[0].balance_amount_new);

          this.details.old_contract = filterNewGes[0].contract_name_old;
          this.details.expired_at_old = moment(
            filterNewGes[0].contract_end_date_old
          )
            .format("DD-MMM-YYYY")
            .toUpperCase();
          this.details.balance_deposit_old =
            Intl.NumberFormat().format(setBalanceDepositOld);
          this.details.transfer_deposit_old = Intl.NumberFormat().format(
            filterNewGes[0].transfer_value
          );
          this.details.new_contract = filterNewGes[0].contract_name_new;
          this.details.expired_at_new = moment(
            filterNewGes[0].contract_end_date_new
          )
            .format("DD-MMM-YYYY")
            .toUpperCase();
          this.details.balance_deposit_new =
            Intl.NumberFormat().format(setBalanceDepositNew);
          this.details.total_deposit =
            Intl.NumberFormat().format(totalDepositSet);
        }
      } catch (error) {
        console.log(error);
      }
    },

    async getDataEmployee() {
      // console.log(`${Auth.getToken()}`);
      try {
        this.myloadingvariable = true;
        // pagination showing start of entries
        let startPage = (this.page - 1) * this.perPage;
        let showPage = this.perPage;
        this.itemShowStart = startPage + 1;
        this.itemShowStop = startPage + showPage;

        const res = await API.get(
          `${process.env.VUE_APP_API_TRUST}business-partner-contract/list-transfer?query=${this.search}&offset=${startPage}&per_page=${showPage}`,
          {
            "Content-Type": "application/json",
            Authorization: `Bearer ${Auth.getToken()}`,
          }
        );

        // Check result exist
        if (res.results) {
          const filterNewData = res.results;

          var new_data = [];
          filterNewData.map(function (e) {
            var dateCreate =
              e.date !== null
                ? moment(e.date).format("DD-MMM-YYYY h:mm:ss a").toUpperCase()
                : "-";
            new_data.push({
              id: typeof e.id !== "undefined" ? e.id : "-",
              transfer_no:
                e.transfer_no !== null ? e.transfer_no.toUpperCase() : "-",
              booking_date: dateCreate,
              contract_name_old:
                e.contract_name_old !== null
                  ? e.contract_name_old.toUpperCase()
                  : "-",
              contract_name_new:
                e.contract_name_new !== null
                  ? e.contract_name_new.toUpperCase()
                  : "-",
              transfer_value: e.transfer_value !== "0" ? e.transfer_value : "0",
              status: e.paid !== null ? e.paid : "-",
            });
          });

          this.datas = new_data;

          this.itemsTotal = res.total;
          this.itemLength = Math.ceil(this.itemsTotal / showPage);
          if (this.itemShowStop < 0) {
            this.itemShowStop = res.total;
          }
          if (this.itemShowStop > res.total) {
            this.itemShowStop = res.total;
          }
          this.myloadingvariable = false;
        }
      } catch (error) {
        console.log(error);
      }
    },
  },
};
</script>
<style>
.table-officecare-company thead {
  z-index: 5 !important;
}
.table-officecare-company thead {
  background: #ddd !important;
  font-weight: bold;
  color: #000 !important;
}
.table-officecare-company thead tr th {
  height: 38px !important;
  white-space: nowrap;
}
.table-officecare-company tbody tr td {
  font-size: 12px !important;
  height: 35px !important;
  white-space: nowrap;
}
.table-officecare-company tbody tr:hover {
  background-color: #eee !important;
}

@media screen and (min-width: 1023px) {
  .sticky-popup-oct1 {
    position: sticky !important;
    position: -webkit-sticky !important;
    left: 0;
    background: #fff;
    z-index: 2;
  }
  .sticky-popup-oct2 {
    position: sticky !important;
    position: -webkit-sticky !important;
    right: 0;
    background: #fff;
    z-index: 2;
  }
  .sticky-popup-hoct1 {
    position: sticky !important;
    position: -webkit-sticky !important;
    left: 0;
    background: #ddd;
    z-index: 5;
  }
  .sticky-popup-hoct2 {
    position: sticky !important;
    position: -webkit-sticky !important;
    right: 0;
    background: #ddd;
    z-index: 5;
  }
}
</style>
<style lang="scss" scoped>
@import "@/assets/css/custom.scss";
.content-table {
  padding: 15px;
  @media screen and (max-width: 960px) {
    padding: 5px 18px 18px 18px;
  }
}
@media screen and (max-width: 480px) {
  .content-table {
    padding: 0px !important;
  }
}
@media screen and (min-width: 1023px) {
  .sticky-popup-hdt:hover {
    .sticky-popup-oct1 {
      background: #eee !important;
    }
    .sticky-popup-oct2 {
      background: #eee !important;
    }
    .v-data-table
      > .v-data-table__wrapper
      tbody
      tr:first-child:hover
      td:first-child {
      background: #eee !important;
    }
  }
}
.items-product {
  width: 300px;
  white-space: break-spaces;
}
.searchField {
  max-width: 500px;
}
.button--active {
  background: $main_3;
  color: white;
}
.button--outline {
  color: $main_3;
  border: 1px solid $main_3;
  background: white;
}
.button--download {
  background: #79c4c6;
  color: white;
}
.button-box {
  box-sizing: border-box;
  border-radius: 10px;
  padding: 8px 16px;
  outline: none;
}
.card--result {
  background: $gray_3;
  padding: 18px;
  border-radius: 16px;
  width: 100%;
  // max-width: 300px;
  color: $gray_2;
  h4 {
    color: $gray_1;
    font-size: 20px;
  }
  p {
    margin: 0;
  }
  .card--result__item {
    padding: 6px 0;
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid $gray_2;
    &:nth-last-child(1) {
      border: none;
    }
  }
}

.status--inprogress {
  color: $yellow_1;
  font-weight: 600;
}
.status--complete {
  color: $green_1;
  font-weight: 600;
}
.status--cancel {
  color: $red_1;
  font-weight: 600;
}
.tabs {
  background: #e5e5e5 !important;
}

td {
  font-size: 12px !important;
  vertical-align: top !important;
}
.fontSet {
  font-size: 14px !important;
}
.button {
  background-color: #00a4b3 !important;
  border-radius: 5px;
  color: white !important;
  font-size: 14px;
  text-align: center;
  text-transform: capitalize;
  height: 30px !important;
  min-width: 100px !important;
}
a {
  text-decoration: none;
}
.fontSet {
  font-size: 14px !important;
}
.box-page-new {
  margin: 20px;
  border-radius: 4px !important;
}

.v-data-table > .v-data-table__wrapper > table > tbody > tr > td {
  font-size: 12px;
  vertical-align: baseline;
  padding-top: 6px;
  padding-bottom: 6px;
  height: 25px !important;
}

.v-data-table > .v-data-table__wrapper > table > thead > tr > th {
  height: 35px !important;
}
.package-title {
  font-size: 16px;
  font-weight: bold;
  color: #233f78;
  margin-bottom: 0;
}
.card-contract {
  border: 1px solid #ddd;
  padding-top: 4px;
  padding-left: 16px;
  padding-right: 16px;
  padding-bottom: 2px;
  margin-bottom: 8px;
  border-radius: 10px;
}
.card-contract-detail {
  border: 1px solid #ddd;
  padding-top: 4px;
  padding-left: 8px;
  padding-right: 8px;
  padding-bottom: 2px;
  margin-bottom: 8px;
  margin-top: 16px;
  border-radius: 10px;
}
</style>
<style lang="scss">
.footerHide {
  background: none !important;
  align-items: center;
  width: 50px;
  font-size: 12px;
}
.footerHide > .v-data-footer__pagination {
  display: none !important;
}
.footerHide > .v-data-footer__icons-before {
  display: none !important;
}
.footerHide > .v-data-footer__icons-after {
  display: none !important;
}
.payment-info .v-data-table > .v-data-table__wrapper > table > tbody > tr > td {
  font-size: 12px;
  vertical-align: baseline;
  padding-top: 6px;
  padding-bottom: 6px;
}
.v-toolbar__title {
  font-size: 16px;
}
.footer-payment {
  margin: 0 auto !important;
}
.footer-showing {
  font-size: 12px !important;
  margin: 0 auto !important;
}

.footerHide > .v-text-field .v-input__prepend-inner,
.v-text-field .v-input__append-inner {
  padding-left: 0 !important;
}
</style>
<style>
/* Hide scrollbar for Chrome, Safari and Opera */
.table-stick-deposit .v-data-table__wrapper::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.table-stick-deposit .v-data-table__wrapper {
  -ms-overflow-style: none;
  scrollbar-width: none;
}
.super-dense > .v-data-table__wrapper > table > tbody > tr > td,
.super-dense > .v-data-table__wrapper > table > tbody > tr > th,
.super-dense > .v-data-table__wrapper > table > thead > tr > td,
.super-dense > .v-data-table__wrapper > table > thead > tr > th,
.super-dense > .v-data-table__wrapper > table > tfoot > tr > td {
  height: 35px !important;
}
.table-stick-deposit .v-data-table__wrapper {
  /* overflow-x: auto; */
  overflow-y: auto;
  height: 400px !important;
}
.table-stick-deposit thead {
  position: sticky;
  inset-block-start: 0;
  top: 0;
  background: #ddd !important;
  font-weight: bold;
  color: #000 !important;
  z-index: 5;
}
</style>